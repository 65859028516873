<script lang="ts">
</script>
<template>
  <div class="main_div">
    <span style="color: white">登录次数：</span>
    <br/>
    <span style="color: white">郭申欣：登录13次</span><br/>
    <span style="color: white">王晓宇：登录8次</span><br/>
    <span style="color: white">石程程：登录1次</span>
  </div>
</template>
<style scoped>
.main_div {
  height: 100%;
  background-color: rgb(241, 125, 79);
  /* 设置圆角 */
  border-radius: 5px;
}
</style>

