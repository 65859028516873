<template>
  <el-dialog title="小程序首页管理" width="1300" :model-value="props.visible" :before-close="handleClose">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="轮播图设置" name="first">
        <uploadImg />
      </el-tab-pane>
      <el-tab-pane label="滚动通知设置" name="second"> </el-tab-pane>
      <el-tab-pane label="新闻设置" name="third">Role</el-tab-pane>
      <el-tab-pane label="轮播视频设置" name="fourth">Role</el-tab-pane>
    </el-tabs>
    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button @click="close"> 关闭 </el-button>
        <el-button type="primary" @click="imgUploadSave(file)">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
// import axios from 'axios'
import { ref } from 'vue'
// import { Delete, Download, Plus, ZoomIn } from '@element-plus/icons-vue'
import uploadImg from '../components/uploadImg.vue'

const activeName = ref('first')
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
</script>
<script>
export default {
  name: 'UniappHomeManage',
  components: { uploadImg },
}
</script>
