<script lang="ts">
</script>
<template>
  <div class="main_div">
    <span style="color: white">生日提醒:</span>
    <br />
    <span style="color: white">今日：</span>
    <span style="color: white">王予涵；张梓萱；<br />李佳洋；冷守业</span>

    <br /><br /><br />
    <span style="color: white">明日：</span>
    <span style="color: white">吴司怡；魏皓冉；<br />刘冲峰</span>
  </div>
</template>
<style scoped>
.main_div {
  height: 100%;
  background-color: rgb(241, 125, 79);
  /* 设置圆角 */
  border-radius: 5px;
}
</style>

