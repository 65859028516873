<template>
  <el-dialog title="小程序轮播图管理" width="1000" :model-value="props.visible" :before-close="handleClose">
    <form @submit.prevent="submitForm">
      <input type="file" @change="handleFileChange" />
      <button type="submit">上传</button>
    </form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="close"> 关闭 </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { onMounted } from 'vue'
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits(['update:visible'])

onMounted(() => {})
const close = () => {
  emits('update:visible', false)
}
// 右上角关闭
const handleClose = () => {
  emits('update:visible', false)
}
import axios from 'axios'

const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (file) {
    // 可以在此进行一些图片的预处理，如压缩等
    uploadFile(file)
  }
}

const uploadFile = async (file) => {
  const formData = new FormData()
  const uniqueFileName = `${Date.now()}_${file.name}` // 添加时间戳以确保唯一性
  formData.append('file', new File([file], uniqueFileName)) // 重命名文件

  try {
    const response = await axios.put('https://hsczty.com/applet/' + uniqueFileName, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log('上传成功', response.data)
  } catch (error) {
    console.error('上传失败', error)
  }
}
</script>
